import React, { Component } from 'react';
import StatisticsFilters from './StatisticsFilters';
import StatisticsChart from './StatisticsChart';
import StatisticsTable from './StatisticsTable';
import StatisticsFiltersChart from './StatisticsFiltersChart';
import StatisticsChartVictory from './StatisticsChartVictory';
import StatisticsChartVictory2 from './StatisticsChartVictory2';
import StatisticsChartReactive from './StatisticsChartReactive';

class Statistics extends Component {
    constructor(props) {
        super();
        this.handleChangeForm = this.handleChangeForm.bind(this);
        this.handleChangeChart = this.handleChangeChart.bind(this);

        this.state = {
            params: ['Albumin', 'Alkaline Phosphatase', 'ALT - GPT', 'AST - GOT', 'Calcium', 'Chloride', 'Creatinine', 'FT4', 'GGT', 'Glucose', 'Inorganic phosphor', 'LDH', 'Magnesium', 'Potassium', 'Sodium', 'total-Bilirubin', 'total-Cholesterol', 'total-Protein', 'TSH', 'Urea', 'Uric acid'],
            units: ['g/L', 'mg/L', 'mIU/L', 'mmol/L', 'pmol/L', 'U/L', 'µmol/L'],
            data: [],
            page: 0,
            rowsPerPage: 10,
            form: {
                param: 'Albumin',
                date: new Date('2015-01-01'),
                toDate: new Date('2015-12-31'),
                lab: props.labs[0],
                device: props.labs[0].devices[0],
            },
            charts: ['Rechart', 'VictoryBar', 'VictoryBar Zoom', 'Reactive'],
            chart: 'Rechart'
        }
    }

    handleChangeForm = (f) => {
        this.setState({ form: f });
    }

    handleChangeChart = (f) => {
        this.setState({ chart: f });
    }


    render() {
        const { classes, labs, form, searchForTheNewMeasurement, handleChangeForm, submitForm, measurements } = this.props;
        const data = this.props.measurements.filter(
            v => v.parameter === this.state.form.param
                && v.lab_name === this.state.form.lab.name
                && v.device_name === this.state.form.device.name
        )[0]
        const results = data && data.results ? data.results : [];
        const resultsRange = results.filter(
            v => v.date >= this.state.form.date.toLocaleDateString('fr-CA')
                && v.date <= this.state.form.toDate.toLocaleDateString('fr-CA'))

        return (
            <>
                <h1>Statistics</h1>
                <StatisticsFilters
                    classes={classes}
                    labs={labs}
                    form={this.state.form}
                    params={this.state.params}
                    handleChangeForm={(e) => this.handleChangeForm(e)}
                />
                <StatisticsFiltersChart
                    handleChangeChart={(e) => this.handleChangeChart(e)}
                    charts={this.state.charts}
                    chart={this.state.chart}
                />

                {this.state.chart === 'Rechart' &&
                    <StatisticsChart
                        classes={classes}
                        results={resultsRange}
                    />
                }

                {this.state.chart === 'VictoryBar' &&
                    <StatisticsChartVictory
                        classes={classes}
                        results={resultsRange}
                    />
                }

                {this.state.chart === 'VictoryBar Zoom' &&
                    <StatisticsChartVictory2
                        classes={classes}
                        results={resultsRange}
                    />
                }
                {this.state.chart === 'Reactive' &&
                    <StatisticsChartReactive
                        classes={classes}
                        results={resultsRange}
                    />
                }
                <StatisticsTable
                    classes={classes}
                    results={resultsRange}
                />
            </>
        );
    }
}

export default Statistics;