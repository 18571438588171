import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Navbar from './components/UI/navigation/Navbar';
import Dashboard from './components/UI/dashboard/Dashboard';
import SignIn from './components/UI/auth/SignIn';
import SignUp from './components/UI/auth/SignUp';

function App() {
  return (

    <Router>
      <Switch>
        <Route path={["/signin", "/signup"]}>
          <Switch>
            <Route exact path='/signin' component={SignIn} />
            <Route exact path='/signup' component={SignUp} />
          </Switch>
        </Route>
        <Route path={["/", "/fill"]}>
          <div style={{ flexGrow: 1, alignItems: 'center' }}>
            <Navbar />
            <Switch>
              <Route exact path='/' component={Dashboard} />
              <Route exact path='/signin' component={SignIn} />
              <Route exact path='/signup' component={SignUp} />
            </Switch>
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

function PrivateRoute({ children, ...rest }) {
  let auth = false;//useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        true ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
export default App;
