import React, { Component } from 'react';
import AnalytesParameterButton from './AnalytesParameterButton';

class AnalytesList extends Component {
    render() {
        const { measurements, params, classes, handleSubmit, form } = this.props;
        const labDeviceParameter = measurements.map(x => x.name);
        let labDevice = form.lab.name + '-' + form.device.name + '-';
        return (
            <div>
                {params &&
                    params.map((measure, index) =>
                        measure &&
                        <AnalytesParameterButton
                            handleSubmit={(e) => handleSubmit(e)}
                            key={index + '-' + measure.lab}
                            measure={measure}
                            index={index}
                            params={params}
                            classes={classes}
                            labDevice={labDevice}
                            labDeviceParameter={labDeviceParameter}
                        />
                    )
                }
                <AnalytesParameterButton
                    handleSubmit={(e) => handleSubmit(e)}
                    key={111}
                    measure={'All'}
                    index={111}
                    params={params}
                    classes={'All'}
                />
            </div>
        );
    }
}

export default AnalytesList;