import React from 'react';
import { connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import MeasuresIndex from './MeasuresIndex';
import LabsIndex from './LabsIndex';
import AddMenu from './AddMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from "./styles";
import MeasureForm from './MeasureForm';
import { useDispatch } from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';
import { updateMeasure, addMeasure, deleteMeasure } from '../../../store/actions/measureActions';
import Import from './Import';
import AnalytesForm from './analytes/AnalytesForm';
import Statistics from './statistics/Statistics';

const Dashboard = (params) => {
  const { permissions, authStatus, userStatus, measurements, users, labs, status, menu, form, searchForTheNewMeasurement, updateMeasure, addMeasure, deleteMeasure } = params;
  const { isEmpty, isLoaded } = authStatus;
  const classes = useStyles();
  const dispatch = useDispatch();
  const a = permissions && Array.isArray(permissions) && permissions[0] ? permissions[0].admin.concat(permissions[0].read.concat(permissions[0].write)) : ['2'];
  const parameters = {
    "ALT - GPT": {
      "target": 21,
      "bias": 9.5
    },
    "AST - GOT": {
      "target": 23,
      "bias": 6.5
    },
    "Albumin": {
      "target": 42,
      "bias": 2.4
    },
    "Alkaline Phosphatase": {
      "target": 73,
      "bias": 6.8
    },
    "CRP": {
      "target": 2.6,
      "bias": 9.6
    },
    "Calcium": {
      "target": 2.35,
      "bias": 1.7
    },
    "Chloride": {
      "target": 102.5,
      "bias": 1
    },
    "Creatinine": {
      "target": 77,
      "bias": 3.9
    },
    "FT4": {
      "target": 15,
      "bias": 3.3
    },
    "GGT": {
      "target": 22,
      "bias": 9.1
    },
    "Glucose": {
      "target": 4.9,
      "bias": 3.1
    },
    "Inorganic phosphor": {
      "target": 1.13,
      "bias": 4.4
    },
    "LDH": {
      "target": 175,
      "bias": 4.6
    },
    "Magnesium": {
      "target": 0.83,
      "bias": 3
    },
    "K": {
      "target": 4.25,
      "bias": 2.4
    },
    "Na": {
      "target": 140.5,
      "bias": 0.7
    },
    "Sodium": {
      "target": 140.5,
      "bias": 0.7
    },
    "TSH": {
      "target": 1.56,
      "bias": 7.7
    },
    "Urea": {
      "target": 5,
      "bias": 6
    },
    "Uric acid": {
      "target": 310,
      "bias": 4.8
    },
    "total-Bilirubin": {
      "target": 10,
      "bias": 10
    },
    "total-Cholesterol": {
      "target": 5.2,
      "bias": 3.8
    },
    "total-Protein": {
      "target": 72,
      "bias": 1.4
    },
    "Potassium": {
      "target": 4.25,
      "bias": 2.4
    }
  };

  useFirestoreConnect([
    {
      collection: "measures",
      where: [
        ['lab', 'in', a],
        // ['parameter', '==', 'Uric acid'],
        // ['device', '==', '1'],
      ],
      limit: 250,
      // orderBy: ['name', 'asc'],
      // collectionGroup:'parameter',
      // doc: '58PuDfz1yrNM0YPLsMRv',
      // subcollections: [{ collection: "device" },],
      storeAs: 'measurements'
    },
    {
      collection: "measures",
      where: [
        ['lab', 'in', [form.lab ?? '1']],
        ['parameter', '==', form.parameter ?? 'Uric acid'],
        ['device', '==', form.device ?? '1'],
      ],
      limit: 25,
      storeAs: 'searchForTheNewMeasurement'
    },
    {
      collection: "laboratory",
      where: [
        ['id', 'in', a],
      ],
      storeAs: 'labs'
    },
    {
      collection: "users",
      doc: authStatus.uid,
    }
    ,
    {
      collection: "user_lab_permissions",
      doc: authStatus.uid,
      //    where: [
      //     ['user', '==', authStatus.uid],
      // ],
      storeAs: 'permissions'
    }
  ]);

  const handleChangeForm = (e) => {
    dispatch({ type: actions.MEASURE_FORM, payload: e })
  };
  const submitForm = () => {
    let weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    if (searchForTheNewMeasurement && searchForTheNewMeasurement[0]) {
      // we found a document
      // insert a new measurement into an existing document
      let newresult = { ...searchForTheNewMeasurement[0] };
      let n = weekday[form.date.getDay()];
      let a = newresult.results.concat({
        date: form.date.toLocaleDateString('fr-CA'),
        result: form.result,
        day: n,
        unit: form.unit
      });
      newresult.results = a;
      updateMeasure(a, searchForTheNewMeasurement[0].id)
      dispatch({ type: actions.MEASURE_FORM, payload: { ...form, result: "", parameter: "" } })
    } else {
      // no document found
      // create a new document
      let l = labs.filter(v => v.id === form.lab)[0]
      let d = l.devices.filter(v => v.id === form.device)[0]
      let newMeasure = {
        device: form.device,
        lab: form.lab,
        lab_name: l.name,
        device_name: d.name,
        parameter: form.parameter,
        unit: form.unit,
        name: form.lab + '-' + form.device + '-' + form.parameter,
        results: [
          {
            date: form.date.toLocaleDateString('fr-CA'),
            result: form.result,
            day: weekday[form.date.getDay()],
            unit: form.unit
          }
        ]
      }
      addMeasure(newMeasure);
      dispatch({ type: actions.MEASURE_FORM, payload: { ...form, result: "", parameter: "" } })
    }
    dispatch({ type: actions.SET_MENU, payload: 'measures' })
  };

  const importNew = (newMeasure) => {
    addMeasure(newMeasure);
  }
  const importAdd = (newMeasure, id) => {
    updateMeasure(newMeasure, id)
  }

  const deleteMeasurement = (x) => {
    let a = Object.values(x.measure.results);
    let b = a.splice(x.index, 1);
    if (a.length > 0) {
      updateMeasure(a, x.measure.id);
    } else {
      deleteMeasure(x.measure.id);
    }
  }
  const addMeasurement = (x) => {
    dispatch({
      type: actions.MEASURE_FORM, payload:
      {
        lab: x.lab,
        device: x.device,
        parameter: x.parameter
      }
    })
    dispatch({ type: actions.SET_MENU, payload: 'form' })
  }
  return (
    <Container className={classes.content}>
      {isLoaded &&
        (isEmpty ? (
          <Redirect to='/signin' />
        ) : (<div>
          {menu === 'measures' &&
            (
              status.requesting.measurements
                ? <CircularProgress className={classes.content} />
                : <MeasuresIndex
                  classes={classes}
                  measurements={measurements}
                  deleteMeasurement={(e) => deleteMeasurement(e)}
                  addMeasurement={(e) => addMeasurement(e)}
                />
            )
          }
          {menu === 'labs' &&
            (
              status.requesting.labs
                ? <CircularProgress className={classes.title} />
                : <LabsIndex classes={classes} labs={labs} />
            )
          }
          {menu === 'form' &&
            (
              status.requesting.searchForTheNewMeasurement
                ? <CircularProgress className={classes.title} />
                : <AnalytesForm
                  measurements={measurements}
                  classes={classes}
                  labs={labs}
                  form={form}
                  state={params.state}
                  searchForTheNewMeasurement={searchForTheNewMeasurement}
                  handleChangeForm={(e) => handleChangeForm(e)}
                  submitForm={(e) => submitForm(e)}
                  addMeasure={(measure) => importNew(measure)}
                  importAdd={(measure, id) => importAdd(measure, id)}
                />
            )
          }
          {menu === 'import' &&
            (<Import
              classes={classes}
              measurements={measurements}
              state={params.state}
              labs={labs}
              form={form}
              submitForm={(e) => submitForm(e)}
              addMeasure={(measure) => importNew(measure)}
              importAdd={(measure, id) => importAdd(measure, id)}
            />
            )
          }
          {menu === 'stat' &&
            (<Statistics
              classes={classes}
              measurements={measurements}
              state={params.state}
              labs={labs}
              form={form}
              submitForm={(e) => submitForm(e)}
              addMeasure={(measure) => importNew(measure)}
              importAdd={(measure, id) => importAdd(measure, id)}
            />
            )
          }
        </div>
        ))}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    parameter: state.firestore.ordered.parameter,
    measurements: state.firestore.ordered.measurements,
    searchForTheNewMeasurement: state.firestore.ordered.searchForTheNewMeasurement,
    authStatus: state.firebase.auth,
    userStatus: state.firebase.auth,
    users: state.firestore.ordered.users,
    permissions: state.firestore.ordered.permissions,
    labs: state.firestore.ordered.labs,
    status: state.firestore.status,
    menu: state.measure.menu,
    form: state.measure.form,
    state: state.measure.update
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMeasure: (results, id) => dispatch(updateMeasure(results, id)),
    addMeasure: (measure) => dispatch(addMeasure(measure)),
    deleteMeasure: (id) => dispatch(deleteMeasure(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
