import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableExpandingRows from './TableExpandingRows';

const MeasuresIndex = (params) => {
    const { classes, measurements } = params;
    return (
        <div>
            <h1>Latest measurements</h1>
            <TableContainer component={Paper}>

                <Table className={classes.table} aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Lab\Device\Parameter</TableCell>
                            <TableCell align="left">Date</TableCell>
                            <TableCell align="left">Device</TableCell>
                            <TableCell align="left">Result</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {measurements &&
                            measurements.map((measure, index) =>
                                measure && measure.results &&
                                <TableExpandingRows
                                    key={index + '-' + measure.lab}
                                    measure={measure}
                                    index={index}
                                    params={params}
                                    classes={classes}
                                />
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>

        </div>
    );
};

export default MeasuresIndex;