import React, { Component } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

class StatisticsTableRow extends Component {
    render() {
        return (
            <TableRow >
                <TableCell component="th" scope="row">
                    {this.props.element.date}
                </TableCell>
                <TableCell component="th" scope="row">
                    {this.props.element.result}
                </TableCell>
                <TableCell component="th" scope="row">
                    {this.props.element.unit}
                </TableCell>
                <TableCell component="th" scope="row">
                    {this.props.element.day}
                </TableCell>
            </TableRow >
        );
    }
}

export default StatisticsTableRow;