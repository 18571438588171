import React, { Component } from 'react';
import AnalytesList from './AnalytesList';
import AnalytesFormRow from './AnalytesFormRow';
import AnalytesDefaultValues from './AnalytesDefaultValues';
import AnalytesImport from './AnalytesImport';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { Check, Close, ImportExport, PostAddOutlined } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import AnalytesParameterButton from './AnalytesParameterButton';
import CsvImport from './CsvImport';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

class AnalytesForm extends Component {
    constructor(props) {
        super();
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDeleteAll = this.handleDeleteAll.bind(this);
        this.handleCsvImport = this.handleCsvImport.bind(this);
        this.handleChangeResult = this.handleChangeResult.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeForm = this.handleChangeForm.bind(this);
        this.handleSetStatus = this.handleSetStatus.bind(this);
        this.importElement = this.importElement.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

        this.state = {
            params: [
                'Albumin',
                'Alkaline Phosphatase',
                'ALT - GPT',
                'AST - GOT',
                'Calcium',
                'Chloride',
                'Creatinine',
                'FT4',
                'GGT',
                'Glucose',
                'Inorganic phosphor',
                'LDH',
                'Magnesium',
                'Potassium',
                'Sodium',
                'total-Bilirubin',
                'total-Cholesterol',
                'total-Protein',
                'TSH',
                'Urea',
                'Uric acid'
            ],
            units: [
                'g/L',
                'mg/L',
                'mIU/L',
                'mmol/L',
                'pmol/L',
                'U/L',
                'µmol/L'
            ],
            data: [],
            page: 0,
            rowsPerPage: 10,
            form: {
                date: new Date('2015-01-01'),
                lab: props.labs[0],
                device: props.labs[0].devices[0],
            }
        }
    }

    handleChangeForm = (f) => {
        this.setState({ form: f });
    }
    handleDelete = (index) => {
        const newArr = [...this.state.data];
        newArr.splice(index, 1);
        this.setState({ data: newArr });
    }
    handleDeleteAll = (index) => {
        this.setState({ data: [] });
    }

    handleCsvImport = (data) => {
        this.setState({ data: data });
    }

    handleSetStatus = (index, status) => {
        const newArr = [...this.state.data];
        newArr[index]['status'] = status;
        this.setState({ data: newArr });
    }

    handleChangeResult = (data) => {
        const newArr = [...this.state.data];
        newArr[data.index][data.field] = data.value;
        if (
            newArr[data.index].parameter.length > 0 &&
            newArr[data.index].result.length > 0 &&
            newArr[data.index].unit.length > 0 &&
            newArr[data.index].lab &&
            newArr[data.index].lab.name &&
            newArr[data.index].lab.name.length > 0 &&
            newArr[data.index].device &&
            newArr[data.index].device.name &&
            newArr[data.index].device.name.length > 0 &&
            newArr[data.index].date instanceof Date && !isNaN(newArr[data.index].date.valueOf())

        ) {
            newArr[data.index]['status'] = 'Processable'
        } else {
            newArr[data.index]['status'] = 'Missing data'
        }
        this.setState({ data: newArr });
    }

    importElement = (element, counter) => {
        let weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        let data = element;
        if (data.status === 'Processable') {
            this.setState({ element: data });
            let d = this.props.measurements.filter(
                v => v.parameter === data.parameter
                    && v.lab_name === data.lab.name
                    && v.device_name === data.device.name
            )[0]
            if (d) {
                let r = d.results.filter(
                    v => v.date === data.date.toLocaleDateString('fr-CA'))[0]
                let ii = d.results.indexOf(r);
                let a = d.results;
                if (ii < 0) {
                    a = d.results.concat({
                        date: data.date.toLocaleDateString('fr-CA'),
                        result: data.result,
                        day: weekday[data.date.getDay()],
                        unit: data.unit
                    });
                } else {
                    a = [...d.results];
                    a[ii] = {
                        date: data.date.toLocaleDateString('fr-CA'),
                        result: data.result,
                        day: weekday[data.date.getDay()],
                        unit: data.unit
                    };
                }


                if (data.status === 'Processable') {
                    this.props.importAdd(a, d.id);
                    this.handleSetStatus(counter, 'Processed');
                }
            } else {
                let l = this.props.labs.filter(v => v.name === data.lab.name)[0]
                let d = l.devices.filter(v => v.name === data.device.name)[0]
                let newMeasure = {
                    device: d.id,
                    lab: l.id,
                    lab_name: data.lab.name,
                    device_name: data.device.name,
                    parameter: data.parameter,
                    unit: data.unit,
                    name: data.lab.name + '-' + data.device.name + '-' + data.parameter,
                    results: [
                        {
                            date: data.date.toLocaleDateString('fr-CA'),
                            result: data.result,
                            day: weekday[data.date.getDay()],
                            unit: data.unit
                        }
                    ]
                }
                if (data.status === 'Processable') {
                    this.props.addMeasure(newMeasure);
                    this.handleSetStatus(counter, 'Processed');
                }
            }
        }
    }

    handleSubmit = element => {
        let data = [];
        if (element === 'All') {
            let a = [];
            this.state.params.forEach(p => {
                let dd = this.props.measurements.filter(
                    v => v.parameter === p
                        && v.lab_name === this.state.form.lab.name
                        && v.device_name === this.state.form.device.name
                )[0];
                let a = {
                    date: this.state.form.date,
                    lab: this.state.form.lab,
                    device: this.state.form.device,
                    day: '',
                    result: '',
                    unit: dd ? dd.unit : '',
                    parameter: p,
                    status: 'Missing data'

                }
                if (dd) { data.push(a) };
            })
            // let d = [...this.state.data, data];
            // console.info('You added .', d);
            this.setState({ data: this.state.data.concat(data) });

        } else {


            let dd = this.props.measurements.filter(
                v => v.parameter === element
                    && v.lab_name === this.state.form.lab.name
                    && v.device_name === this.state.form.device.name
            )[0];
            data = {
                date: this.state.form.date,
                lab: this.state.form.lab,
                device: this.state.form.device,
                day: '',
                result: '',
                unit: dd ? dd.unit : '',
                parameter: element,
                status: 'Missing data'

            }
            console.info('You added .', data);
            let d = [...this.state.data, data];
            console.info('You added .', d);
            this.setState({ data: d });
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 });
    };

    render() {
        const { classes, labs, form, searchForTheNewMeasurement, handleChangeForm, submitForm, measurements } = this.props;
        return (
            <div>
                <Box display="flex" p={1}>
                    <Box p={1} flexGrow={1}>
                        <AnalytesDefaultValues
                            classes={classes}
                            labs={labs}
                            form={this.state.form}
                            handleChangeForm={(e) => this.handleChangeForm(e)}
                        />
                    </Box>
                    <Box p={1}>
                        <AnalytesImport
                            classes={classes}
                            data={this.state.data}
                            state={this.props.state}
                            measurements={measurements}
                            labs={labs}
                            addMeasure={(measure) => this.props.addMeasure(measure)}
                            importAdd={(measure, id) => this.props.importAdd(measure, id)}
                            handleSetStatus={(index, status) => this.handleSetStatus(index, status)}
                            importElement={(element, counter) => this.importElement(element, counter)}
                        />
                        <Button
                            disabled={this.state.data.length === 0}
                            type='submit'
                            // variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => {
                                this.handleDeleteAll();
                            }}
                        >
                            <Close />Delete all
                        </Button>
                        {/* <AnalytesParameterButton
                            handleSubmit={(e) => this.handleSubmit(e)}
                            key={111}
                            measure={'All'}
                            index={111}
                            params={this.state.params}
                            classes={'All'}
                        /> */}
                        <Button

                            type='submit'
                            // variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => {
                                this.handleSubmit('All');
                            }}
                        >
                            <PostAddOutlined /> Add all params
                        </Button>
                        <CsvImport
                            classes={classes}
                            data={this.state.data}
                            state={this.props.state}
                            measurements={measurements}
                            params={this.state.params}
                            units={this.state.units}
                            labs={labs}
                            handleCsvImport={(data) => this.handleCsvImport(data)}
                        />
                    </Box>
                </Box>
                <AnalytesList
                    params={this.state.params}
                    measurements={measurements}
                    form={this.state.form}
                    classes={classes}
                    handleSubmit={(e) => this.handleSubmit(e)}
                />
                <TableContainer
                    style={{ border: "1px solid rgba(0,0,0,0.2)", padding: 4 }}
                    className={classes.table}
                    component={Paper}>

                    <Table size="small" classes={{ root: classes.customTable }} aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ maxWidth: '200', padding: '0' }} component="th" scope="row">
                                    Parameter
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    Date
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    Result
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    Unit
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    Lab
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    Device
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {/* <AnalytesImport
                                        classes={classes}
                                        data={this.state.data}
                                        state={this.props.state}
                                        measurements={measurements}
                                        labs={labs}
                                        addMeasure={(measure) => this.props.addMeasure(measure)}
                                        importAdd={(measure, id) => this.props.importAdd(measure, id)}
                                        handleSetStatus={(index, status) => this.handleSetStatus(index, status)}
                                        importElement={(element, counter) => this.importElement(element, counter)}
                                    />
                                    <IconButton
                                        disabled={this.state.data.length === 0}
                                        type='submit'
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={() => {
                                            this.handleDeleteAll();
                                        }}
                                    >
                                        <Close />
                                    </IconButton> */}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {/* <AnalytesParameterButton
                                        handleSubmit={(e) => this.handleSubmit(e)}
                                        key={111}
                                        measure={'All'}
                                        index={111}
                                        params={this.state.params}
                                        classes={'All'}
                                    />
                                    <CsvImport
                                        classes={classes}
                                        data={this.state.data}
                                        state={this.props.state}
                                        measurements={measurements}
                                        params={this.state.params}
                                        units={this.state.units}
                                        labs={labs}
                                        handleCsvImport={(data) => this.handleCsvImport(data)}
                                    /> */}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                    .map((element, index) => (
                                        <AnalytesFormRow
                                            key={index}
                                            index={index}
                                            element={element}
                                            classes={classes}
                                            labs={labs}
                                            handleDelete={(e) => this.handleDelete(e)}
                                            handleChangeResult={(e, result) => this.handleChangeResult(e, result)}
                                            importElement={(element, counter) => this.importElement(element, counter)}
                                        />
                                    ))
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    style={{ width: '500px', display: 'flex' }}
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={3}
                                    count={this.state.data.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={(e, a) => this.handleChangePage(e, a)}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                // ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}

export default AnalytesForm;