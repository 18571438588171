import React, { Component } from 'react';
import { VictoryBar, VictoryChart, VictoryLabel, VictoryVoronoiContainer, VictoryTooltip, VictoryZoomContainer, VictoryScatter, VictoryLine, VictoryBrushContainer, VictoryAxis } from 'victory';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import * as V from 'victory';

class StatisticsChartVictory extends Component {

    render() {
        const data = this.props.results.map((e) => ({ x: new Date(e.date), y: parseFloat(e.result) }));
        return (
            <div>
                {data && data.length > 0 &&
                    <VictoryChart
                        scale={{ x: "time" }}
                        padding={{ top: 0, left: 50, right: 50, bottom: 30 }}
                        containerComponent={
                            <VictoryZoomContainer
                                zoomDimension="x"
                            />
                        }
                    >
                        <VictoryLine
                            data={data}
                        />
                    </VictoryChart>
                }
            </div>
        );
    }
}

export default StatisticsChartVictory;