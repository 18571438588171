import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { signIn } from '../../../store/actions/authActions';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

function Copyright() {
  return (
    <Link href="/signup" variant="body2">
    {"Regisztráció"}
  </Link>
  );
}


const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(2),
  },
  marginButton: {
    marginTop: theme.spacing(4),
  },
  rounded: {
    color: '#fff',
    backgroundColor: green[500],
    marginTop: theme.spacing(4),
  },
  standardError: {
    width: '100%',
    marginTop: theme.spacing(5),
  },
  circularProgressWhite: {
    color: '#fff',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: 10,
    borderColor: '#dadce0',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '50%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

  },
  submit: {
    marginTop: theme.spacing(2),
  },
}));

const SignIn = ({ userStatus, authStatus, signIn }) => {
  const { isEmpty, isLoaded } = authStatus;
  const { loading, error } = userStatus;

  const classes = useStyles();

  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    e.preventDefault();
    setCredentials({ ...credentials, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    signIn(credentials);
  };

  if (isLoaded && !isEmpty) return <Redirect to='/' />;

  if (isLoaded && isEmpty) {
    return (
      <Grid container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
        margin="2rem"
      >
        <CssBaseline />
        <div className={classes.paper}>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Bejelentkezés
            </Typography>
          </Box>
          <form onSubmit={handleSubmit} className={classes.form}>
            <Box
              display='flex'
              flexDirection='row'
              flexWrap='wrap'
              alignItems='center'
              justifyContent='space-between'
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                type='email'
                id='email'
                label='Email'
                fullWidth
                value={credentials.email}
                onChange={handleChange}
                className={classes.margin}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                required
                id='password'
                label='Password'
                type='password'
                value={credentials.password}
                onChange={handleChange}
                className={classes.margin}
              />
              <Button
                type='submit'
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {loading ? (
                  <CircularProgress className={classes.circularProgressWhite} />
                ) : (
                  'Bejelentkezés'
                )}
              </Button>
              {error ? (
                <Alert
                  variant='filled'
                  severity='error'
                  className={classes.standardError}
                >
                  Invalid Email or Password
                </Alert>
              ) : null}
            </Box>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Grid >
    );
  } else return <></>;
};

const mapStateToProps = (state) => {
  return {
    userStatus: state.auth,
    authStatus: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (credentials) => dispatch(signIn(credentials)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
