import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    secondary: {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
    },
  };
});

const SignedInLinks = ({ signOut, profile }) => {
  const classes = useStyles();
  return (
    <>
      <Button color='inherit' onClick={signOut}>
        Logout
      </Button>
      <Button>
        <Avatar className={classes.primary}>{profile.initials}</Avatar>
      </Button>
    </>
  );
};

export default SignedInLinks;
