import React, { Component } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    DatePicker
} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';

class MeasureForm extends Component {
    constructor(props) {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeLab = this.handleChangeLab.bind(this);
        const selectedLab = props.labs && props.form.lab ? props.labs.filter(v => v.id === props.form.lab)[0] : props.labs[0];
        const selectedDevice = props.form.device ?? 0;//selectedLab && props.form.device ? selectedLab.devices.filter(v => v.id === props.form.device)[0] : {id:'0'};
        const selectedParameter = props.form.parameter ?? '0'
        this.state = {
            val: {
                lab: selectedLab ? selectedLab.id : '0',
                device: selectedDevice,
                parameter: selectedParameter,
                unit: props.searchForTheNewMeasurement && props.searchForTheNewMeasurement[0] ? props.searchForTheNewMeasurement[0].unit : '0',
                date: props.form.date ?? new Date()
            },
            // isExist: searchForTheNewMeasurement && searchForTheNewMeasurement[0] && searchForTheNewMeasurement[0].unit ? true : false,
            isExist: props.searchForTheNewMeasurement && props.searchForTheNewMeasurement[0] ? true : false,
            errors: {},
            params: [
                'Albumin',
                'Alkaline Phosphatase',
                'ALT - GPT',
                'AST - GOT',
                'Calcium',
                'Chloride',
                'Creatinine',
                'FT4',
                'GGT',
                'Glucose',
                'Inorganic phosphor',
                'LDH',
                'Magnesium',
                'Potassium',
                'Sodium',
                'total-Bilirubin',
                'total-Cholesterol',
                'total-Protein',
                'TSH',
                'Urea',
                'Uric acid'
            ],
            units: [
                'g/L',
                'mg/L',
                'mIU/L',
                'mmol/L',
                'pmol/L',
                'U/L',
                'µmol/L'
            ]
        }
    }

    handleChangeLab(e) {
        let n = this.state.val;
        const selectedLab = e.target.value ? this.props.labs.filter(v => v.id === e.target.value)[0] : this.props.labs[0];
        n[e.target.name] = selectedLab.id;
        this.props.handleChangeForm(n);
        n['device'] = selectedLab && selectedLab.devices ? selectedLab.devices[0].id : null;
        this.setState({
            val: { ...n }
        });
        let ee = this.state.errors;
        ee[e.target.name] = e.target.value ? '' : 'Nem lehet üres';
        this.setState({
            errors: ee
        });
    }

    handleChange(e) {
        let n = this.state.val;
        n[e.target.name] = e.target.value;
        this.setState({
            val: { ...n }
        });
        this.props.handleChangeForm(n);
    }
    handleChangeDate(date) {
        let n = this.state.val;
        n['date'] = date
        this.setState({
            val: { ...n }
        });
        this.props.handleChangeForm(n);
    }

    render() {
        const { labs, searchForTheNewMeasurement, classes } = this.props;
        const selectedLab = this.state.val['lab'] ? labs.filter(v => v.id === this.state.val['lab'])[0] : labs[0];
        return (
            <div>
                <h1>Measure</h1>
                {this.state.isExist ? 'Update' : 'new'}
                <form>
                    <div>
                        <TextField
                            required
                            id={"labid"}
                            value={this.state.val['lab'] ?? ""}
                            name={"lab"}
                            select
                            label={"Lab"}
                            onChange={this.handleChangeLab}
                            helperText={"Select lab"}
                        >
                            {labs.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div>
                        < TextField
                            required
                            id={"device"}
                            name={"device"}
                            // defaultValue={this.state.val['device']}
                            value={this.state.val['device'] ?? ""}
                            select
                            label={"Device"}
                            onChange={this.handleChange}
                            helperText={"Select device"}
                        >
                            {selectedLab && selectedLab.devices &&
                                selectedLab.devices.map((option) => (
                                    <MenuItem key={selectedLab.id + option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </div>
                    <div>
                        < TextField
                            required
                            id={"parameter"}
                            name={"parameter"}
                            defaultValue={selectedLab ? selectedLab.devices[0].id : '0'}
                            value={this.state.val['parameter']}
                            select
                            label={"Parameter"}
                            onChange={this.handleChange}
                            helperText={"Select parameter"}
                        >
                            {this.state.params.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div>
                        < TextField
                            required
                            disabled={this.state.isExist}
                            id={"unit"}
                            name={"unit"}
                            // defaultValue={selectedLab.devices[0].id}
                            value={this.state.val['unit']}
                            select
                            label={"unit"}
                            onChange={this.handleChange}
                            helperText={"Select unit"}
                        >
                            {this.state.units.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div>
                        < TextField
                            required
                            id={"result"}
                            name={"result"}
                            // defaultValue={selectedLab.devices[0].id}
                            value={this.state.val['result']}
                            label={"result"}
                            onChange={this.handleChange}
                            helperText={"Enter value"}
                        >
                        </TextField>
                    </div>
                    <div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date"
                                name="date"
                                label="Date picker inline"
                                value={this.state.val['date']}
                                onChange={this.handleChangeDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div>
                        <Button
                            variant='contained'
                            color='primary'
                            className={classes.button}
                            onClick={() => {
                                this.props.submitForm();
                            }}
                        >
                            Kitöltés
                        </Button>
                    </div>
                </form>
            </div >
        );
    }
}

export default MeasureForm;