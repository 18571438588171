import firebase from 'firebase/app';
import * as actions from './actionTypes';

export const signIn = (credentials) => async (dispatch) => {
  try {
    dispatch({ type: actions.SIGN_IN_START });
    await firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password);
    dispatch({ type: actions.SIGN_IN_SUCESS });
  } catch (err) {
    dispatch({ type: actions.SIGN_IN_FAIL, err });
  }
};

export const signUp = (newUser) => async (dispatch) => {
  try {
    dispatch({ type: actions.SIGN_UP_START });
    const firestore = firebase.firestore();
    const response = await firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password);

    await firestore
      .collection('users')
      .doc(response.user.uid)
      .set({
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        email:newUser.email,
        initials: `${newUser.firstName[0]}${newUser.lastName[0]}`,
      });
    dispatch({ type: actions.SIGN_UP_SUCESS });
  } catch (err) {
    dispatch({ type: actions.SIGN_UP_FAIL, err });
  }
};

export const signOut = () => async (dispatch) => {
  try {
    dispatch({ type: actions.SIGN_OUT_START });
    await firebase.auth().signOut();
    dispatch({ type: actions.SIGN_OUT_SUCESS });
  } catch (err) {
    dispatch({ type: actions.SIGN_OUT_FAIL, err });
  }
};
