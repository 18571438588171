import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import StatisticsTableRow from './StatisticsTableRow';

class StatisticsTable extends Component {
    constructor(props) {
        super();
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.state = {
            page: 0,
            rowsPerPage: 10,
        }
    }
    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 });
    };
    render() {
        const { classes, results } = this.props;
        return (
            <TableContainer
                style={{ border: "1px solid rgba(0,0,0,0.2)", padding: 4 }}
                className={classes.table}
                component={Paper}>

                <Table size="small" classes={{ root: classes.customTable }} aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ maxWidth: '200', padding: '0' }} component="th" scope="row">
                                Date
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Result
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Unit
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Day
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            results.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                .map((element, index) => (
                                    <StatisticsTableRow
                                        key={index}
                                        index={index}
                                        element={element}
                                        classes={classes}
                                    />
                                ))
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                style={{ width: '500px', display: 'flex' }}
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={3}
                                count={results.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={(e, a) => this.handleChangePage(e, a)}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            // ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        );
    }
}

export default StatisticsTable;