import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Delete, Visibility, Edit } from '@material-ui/icons';

const LabsIndex = (params) => {
    const { classes, labs } = params;
    return (
        <div>
            <h1>Laboratories</h1>
            <TableContainer component={Paper}>

                <Table className={classes.table} aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Operation</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {labs &&
                            labs.map((lab, index) =>
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {lab.id}
                                    </TableCell>
                                    <TableCell align="left" component="th" scope="row">
                                        {lab.name}
                                    </TableCell>
                                    <TableCell align="left" component="th" scope="row">
                                        <Visibility /><Delete /><Edit />
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default LabsIndex;