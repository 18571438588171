import React, { Component } from 'react';
import { VictoryBar, VictoryChart, VictoryLabel, VictoryVoronoiContainer, VictoryTooltip, VictoryZoomContainer, VictoryScatter, VictoryLine, VictoryBrushContainer, VictoryAxis } from 'victory';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import * as V from 'victory';

const data = [
    { quarter: 1, earnings: 13000 },
    { quarter: 2, earnings: 16500 },
    { quarter: 3, earnings: 14250 },
    { quarter: 4, earnings: 19000 }
];
class StatisticsChartVictory2 extends Component {

    constructor() {
        super();
        this.state = {};
    }

    handleZoom(domain) {
        this.setState({ selectedDomain: domain });
    }

    handleBrush(domain) {
        this.setState({ zoomDomain: domain });
    }

    onDomainChange(domain) {
        // this.setState({
        //   zoomedXDomain: domain.x,
        // });
    }

    render() {
        const data = this.props.results.map((e) => ({ x: new Date(e.date), y: parseFloat(e.result) }));
        return (
            <div style={{
                width: "90%",
                height: "600px",
                // backgroundColor: "black"
            }}>
                <VictoryChart
                    width={550}
                    // height={300}
                    scale={{ x: "time" }}
                    containerComponent={
                        <VictoryZoomContainer responsive={false}
                            zoomDimension="x"
                            zoomDomain={this.state.zoomDomain}
                            onZoomDomainChange={this.handleZoom.bind(this)}
                        />
                    }
                >
                    <VictoryLine
                        style={{
                            data: { stroke: "tomato" }
                        }}
                        data={data}
                    />

                </VictoryChart>

                <VictoryChart
                    width={550}
                    height={90}
                    scale={{ x: "time" }}
                    padding={{ top: 0, left: 50, right: 50, bottom: 30 }}
                    containerComponent={
                        <VictoryBrushContainer responsive={false}
                            brushDimension="x"
                            brushDomain={this.state.selectedDomain}
                            onBrushDomainChange={this.handleBrush.bind(this)}
                        />
                    }
                >
                    <VictoryAxis
                        //   tickValues={h2}
                        tickFormat={(x) => new Date(x).getFullYear()}
                    />
                    <VictoryLine
                        style={{
                            data: { stroke: "tomato" }
                        }}
                        data={data}
                    />
                </VictoryChart>
            </div>
        );
    }
}

export default StatisticsChartVictory2;