import firebase from 'firebase/app';
import * as actions from './actionTypes';

export const updateMeasure = (results, id) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    console.log('START', results);
    const firestore = firebase.firestore();
    await firestore.collection('measures').doc(id).update({
      results: results,
      updatedAt: new Date(),
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
    console.log('END');
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
    console.log('END');
  }
};
export const addMeasure = (measure) => async (dispatch, getState) => {
  try {
    dispatch({ type: actions.ADD_TODO_START });
    const uid = getState().firebase.auth.uid;
    const firestore = firebase.firestore();
    await firestore.collection('measures').add({
      ...measure,
      authorId: uid,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    dispatch({ type: actions.ADD_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.ADD_TODO_FAIL, err });
  }
};

export const deleteMeasure = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('measures').doc(id).delete();
    dispatch({ type: actions.DELETE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
};

export const setMenu = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.SET_MENU, id });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
}
