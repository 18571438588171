import React, { Component } from 'react';
import { readString } from 'react-papaparse';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import { ImportExport } from '@material-ui/icons';

class CsvImport extends Component {
    constructor(props) {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleStop = this.handleStop.bind(this);
    }
    state = {
        task: "",
        // checked: "false",
        // counter: 0,
        // max: 0,
        // data: {},
        // element: null,
        // firstLine: '',
        // timer: null,
        open: false,
    };
    val = '"date";"day";"result";"unit";"parameter";"device";"device-model";"device-vendor";"lab";"user";"device-id"';
    handleSubmit = (e) => {
        this.setState({ open: true });
    }
    handleStop = (e) => {
        this.setState({ open: false });
    }
    handleImport = (e) => {
        let rows = [];
        let data = {};
        const results = readString(this.state.task, { delimiter: ";" });
        const firstLine = results.data.shift();
        results.data.forEach(element => {
            let lab = element[firstLine.indexOf('lab')] ? element[firstLine.indexOf('lab')] : null;
            let device = element[firstLine.indexOf('device')] ? element[firstLine.indexOf('device')] : null;
            let l = lab ? this.props.labs.filter(v => v.name === lab)[0] : null
            let d = device && l ? l.devices.filter(v => v.name === device)[0] : null
            let result = element[firstLine.indexOf('result')] ? element[firstLine.indexOf('result')] : null;
            let unit = element[firstLine.indexOf('unit')] ? element[firstLine.indexOf('unit')] : null;
            let parameter = element[firstLine.indexOf('parameter')] ? element[firstLine.indexOf('parameter')] : null;
            data = {
                date: element[firstLine.indexOf('date')] ? new Date(element[firstLine.indexOf('date')]) : "",
                lab: l ? l : "",
                day: element[firstLine.indexOf('day')] ? element[firstLine.indexOf('day')] : "",
                result: result,
                unit: this.props.units.indexOf(unit) > -1 ? unit : "",
                parameter: this.props.params.indexOf(parameter) > -1 ? parameter : "",
                device: d ? d : "",
                status: l && d && result && this.props.units.includes(unit) && this.props.params.includes(parameter) ? 'Processable' : 'Missing data'
            }
            rows.push(data);
            this.props.handleCsvImport(rows);
        });
        this.setState({ open: false });
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
    };
    render() {
        const { classes } = this.props;
        return (
            <>
                <Button
                    type='submit'
                    // variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => {
                        this.handleSubmit();
                    }}
                >
                    <ImportExport /> Import CSV
                </Button>
                <Dialog
                    fullWidth={true}
                    classes={{ paper: classes.csvdialog }}
                    open={this.state.open}
                    // onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Import CSV data</DialogTitle>
                    <DialogContent>
                        <TextField
                            rowsMin={30}
                            id="task"
                            // aria-label="maximum height"
                            // placeholder="Maximum 4 rows"
                            defaultValue={this.val}
                            onChange={this.handleChange}
                            // inputProps={{ className: classes.textarea }}
                            multiline
                            classes={{ paper: classes.textarea }}
                            className={classes.textarea}
                            variant="outlined"
                            rows={10}
                        />
                    </DialogContent>
                    <DialogActions>

                        <Button
                            onClick={() => {
                                this.handleStop();
                            }}
                            color="primary"
                            autoFocus
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                this.handleImport();
                            }}
                            color="primary"
                            variant="contained"
                            autoFocus
                        >
                            Import
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default CsvImport;