import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

/*The apiKey for the firebase project, should be exposed so that the users can interact with the firebase project. The apiKey in this configuration snippet just identifies your Firebase project on the Google server ->  https://stackoverflow.com/questions/37482366/is-it-safe-to-expose-firebase-apikey-to-the-public*/

var firebaseConfig = {
  apiKey: "AIzaSyAQzDuTQqvKi_fwii0-QGFUEo2F1XiNIFU",
  authDomain: "teszt-296513.firebaseapp.com",
  databaseURL: "https://teszt-296513.firebaseio.com",
  projectId: "teszt-296513",
  storageBucket: "teszt-296513.appspot.com",
  messagingSenderId: "965438652085",
  appId: "1:965438652085:web:8b38da38efe796dc8b88fc"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyB6CwMAEnha6xcOczk83-Wr7OySBGnws2M",
//   authDomain: "smart-classroom-1ec70.firebaseapp.com",
//   databaseURL: "https://smart-classroom-1ec70.firebaseio.com",
//   projectId: "smart-classroom-1ec70",
//   storageBucket: "smart-classroom-1ec70.appspot.com",
//   messagingSenderId: "206322658265",
//   appId: "1:206322658265:web:d85463bdf42c3d3c45c7c8"
// };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
