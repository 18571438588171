import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import {
    Chart,
    ArgumentAxis,
    ValueAxis,
    LineSeries,
    ZoomAndPan,
} from '@devexpress/dx-react-chart-material-ui';
import { scaleTime } from 'd3-scale';
import { ArgumentScale } from '@devexpress/dx-react-chart';

class StatisticsChartReactive extends Component {
    render() {
        // const data = this.props.results.map((e) => ({ x: new Date(e.date), y: parseFloat(e.result) }));
        const data = this.props.results.map((e) => ({ x: e.date, y: parseFloat(e.result) }));
        return (
            <Paper>
                <Chart data={data}>
                    <ArgumentScale />
                    <ArgumentAxis defaultVisualRange={{ startValue: '2015-01-01', endValue: '2015-0-01' }} />
                    <ValueAxis />
                    <LineSeries valueField="y" argumentField="x" />
                    <ZoomAndPan />
                </Chart>
            </Paper>
        );
    }
}

export default StatisticsChartReactive;