import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';

class AnalytesParameterButton extends Component {
    handleClick = (p) => {
        this.props.handleSubmit(p);
        console.info('You clicked the Chip.', p);
    };

    render() {
        const { measure, classes, labDeviceParameter, labDevice } = this.props;
        return (
            <>
                {/* <Chip
                    avatar={<Avatar>P</Avatar>}
                    label={measure}
                    variant={measure === 'All' ? "default" : "outlined"}
                    size="small"
                    color="primary"
                    onClick={() => this.handleClick(measure)}
                /> */}


                <Button
                    variant={labDeviceParameter && labDeviceParameter.includes(labDevice + measure) ? "contained" : "outlined"}
                    color="primary"
                    size="small"
                    className={classes.button}
                    // endIcon={<Icon>send</Icon>}
                    onClick={() => this.handleClick(measure)}
                >
                    {measure}
                </Button>
            </>
        );
    }
}

export default AnalytesParameterButton;