import React, { Component } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Check, Close, ImportExport } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { MenuItem, TextField } from '@material-ui/core';

class AnalytesFormRow extends Component {
    constructor(props) {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeUnit = this.handleChangeUnit.bind(this);
        this.state = {
            units: [
                'g/L',
                'mg/L',
                'mIU/L',
                'mmol/L',
                'pmol/L',
                'U/L',
                'µmol/L'
            ],
            val: {}
        }
    }
    handleChange(e) {
        this.props.handleChangeResult({ index: this.props.index, field: 'result', value: e.target.value });
    }
    handleChangeUnit(e) {
        this.props.handleChangeResult({ index: this.props.index, field: 'unit', value: e.target.value });
    }

    render() {
        const { index, element, classes } = this.props;
        return (
            <TableRow className={
                element.status === 'Missing data'
                    ? classes.error
                    :
                    (
                        element.status === 'Processed' ? classes.processed : classes.normal
                    )
            } >

                <TableCell className={classes.tableCell} component="th" scope="row">
                    <form>
                        <h2>{element.parameter}</h2>
                    </form>
                </TableCell>
                <TableCell component="th" scope="row">
                    {element.date && element.date instanceof Date && !isNaN(element.date.valueOf()) && element.date.toLocaleDateString('fr-CA')}
                </TableCell>
                <TableCell component="th" scope="row">
                    < TextField
                        inputProps={{ tabIndex: this.props.index + 1 }}
                        required
                        type="number"
                        id={"result"}
                        name={"result"}
                        // defaultValue={selectedLab.devices[0].id}
                        value={element.result}
                        // label={"result"}
                        onChange={this.handleChange}
                        helperText={"Result"}
                    >
                    </TextField>
                </TableCell>
                <TableCell component="th" scope="row">
                    < TextField
                        // inputProps={{ tabIndex: this.props.index*2+1 }}
                        required
                        // disabled={this.state.isExist}
                        id={"unit"}
                        // defaultValue={selectedLab.devices[0].id}
                        value={element.unit}
                        select
                        onChange={this.handleChangeUnit}
                        helperText={"Select unit"}
                    >
                        {this.state.units.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </TableCell>
                <TableCell component="th" scope="row">
                    {element.lab.name}
                </TableCell>
                <TableCell component="th" scope="row">
                    {element.device.name}

                </TableCell>
                <TableCell component="th" scope="row">
                    <IconButton
                        disabled={element.status != 'Processable'}
                        type='submit'
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        // onClick={this.handleSubmit100}
                        onClick={() => {
                            this.props.importElement(element, index);
                        }}
                    >
                        <Check />
                    </IconButton>
                    <IconButton
                        type='submit'
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            this.props.handleDelete(index);
                        }}
                    >
                        <Close />
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {element.status}
                </TableCell>
            </TableRow >
        );
    }
}

export default AnalytesFormRow;