import React, { Component } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
class StatisticsChart extends Component {
    render() {
        return (
            <div>
                <div style={{
                    width: "90%",
                    height: "600px",
                    // backgroundColor: "black"
                }}>
                    <ResponsiveContainer width="100%"
                        height="100%">
                        <LineChart
                            width={500}
                            auto
                            height={300}
                            data={this.props.results}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line
                                type="monotone"
                                dataKey="result"
                                stroke="#8884d8"
                                activeDot={{ r: 8 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );
    }
}

export default StatisticsChart;