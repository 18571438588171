import React, { Component } from 'react';
import { MenuItem, TextField } from '@material-ui/core';

class StatisticsFiltersChart extends Component {
    constructor(props) {
        super();
        this.handleChangeParam = this.handleChangeParam.bind(this);
        this.state = {
            chart: props.chart
        }
    }

    handleChangeParam(param) {
        this.setState({
            chart: param.target.value
        });
        this.props.handleChangeChart(param.target.value);
    }

    render() {
        const { charts } = this.props;
        return (
            <form>
                <TextField
                    required
                    id={"chart"}
                    value={this.state.chart}
                    name={"param"}
                    select
                    label={"Chart"}
                    onChange={this.handleChangeParam}
                //  helperText={"Select lab"}
                >
                    {charts.map((option1) => (
                        <MenuItem key={option1} value={option1}>
                            {option1}
                        </MenuItem>
                    ))}
                </TextField>
            </form>
        );
    }
}

export default StatisticsFiltersChart;