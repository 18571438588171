import React, { Component } from 'react';
import { readString } from 'react-papaparse';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import { Check, Close, DoneAll } from '@material-ui/icons';

class AnalytesImport extends Component {
    constructor(props) {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    state = {
        task: "",
        checked: "false",
        counter: 0,
        max: 0,
        data: {},
        element: null,
        firstLine: '',
        timer: null,
        open: false
    };

    incrementCounter() {
        const { counter, data } = this.state;
        if (this.props.state.loading) {
        } else {
            let element = data[counter];
            this.setState({ element: element });
            this.props.importElement(element, counter);
            this.setState({ counter: counter + 1 });
        }
        if (counter >= data.length - 1) {
            this.setState({ counter: 0 });
            this.setState({ open: false });
            clearInterval(this.state.timer);
        }

    }
    handleSubmit = (e) => {
        // e.preventDefault();
        this.setState({ open: true });
        const thisBoundedIncrementer = this.incrementCounter.bind(this);
        let timer = setInterval(thisBoundedIncrementer, 1000);
        this.setState({ timer: timer, data: this.props.data });
    }
    handleStop = (e) => {
        this.setState({ counter: 0 });
        clearInterval(this.state.timer);
        this.setState({ open: false });
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <Button
                    disabled={this.props.data.length === 0}
                    type='submit'
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => {
                        this.handleSubmit();
                    }}
                >
                    <DoneAll />Save all
                </Button>

                <Dialog classes={{ paper: classes.dialog }}
                    open={this.state.open}
                    // onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Import"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.counter}/{this.state.data.length}
                            {this.state.element && <div>
                                <div>Date: {this.state.element.date.toLocaleDateString('fr-CA')}</div>
                                <div>Lab: {this.state.element.lab.name}</div>
                                <div>Device: {this.state.element.device.name}</div>
                                <div>Parameter: {this.state.element.parameter}</div>
                                <div>Result: {this.state.element.result}</div>
                            </div>
                            }
                            <LinearProgress variant="determinate" value={this.state.data.length > 0 ? 100 * (this.state.counter / this.state.data.length) : 0} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>

                        <Button
                            onClick={() => {
                                this.handleStop();
                            }}
                            color="primary"
                            autoFocus
                        >
                            Stop
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default AnalytesImport;